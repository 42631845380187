import onLoadAsync from '@/shared/on_load_async.js';

onLoadAsync(() => {
  document.querySelectorAll('.js-site-select').forEach((select) => {
    const form = select.closest('form');
    select.addEventListener('change', () => {
      form.submit();
    });
  });
});
