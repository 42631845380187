/* eslint no-console:0 */
import Rails from '@rails/ujs';

import '@/shared/components/accordion.js';
import '@/shared/components/burger_menu.js';
import '@/shared/components/dropdown.js';
import '@/shared/components/dropdown_advanced.js';
import '@/shared/components/modal.js';
import '@/shared/klaro-config.js';
import '../ecs/date_input_select.js';
import '../ecs/loader.js';
import '../ecs/login.js';
import '../ecs/password_field.js';
import '../ecs/file-downloader.js';
import '../ecgcc/site_select.js';

Rails.start();
